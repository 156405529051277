.date-filter {
  margin-bottom: 0px !important;
  &__select {
    border: 1px solid #c3c3c3 !important;
    width: 100%;
    height: 40px;
    color: #8b8b8b;
    font-size: 14px;
    &:hover {
      border-color: rgba(0, 0, 0, 0.87) !important;
    }
    ul {
      width: 382px;
      li {
        margin: 0 !important;
        .button {
          font-size: 12px;
        }
      }
    }
  }
}
