.date-range-container {
  display: flex;
  margin: 8px 0px 0px 25px;
  flex-direction: column;
  justify-content: flex-end;
  &-inputs {
    display: flex;
    flex-direction: row;
    .date-range-input {
      margin-bottom: 8px;
    }
  }
  &-button {
    height: 3.5em !important;
    align-self: flex-end !important;
    margin-bottom: 4px !important;
    border: none !important;
    width: 90px !important;
    margin-left: 0px !important;
    font-size: 12px !important;
  }
  .error-message {
    display: flex;
    align-items: center;
    color: #f00;
    &__title {
      display: flex;
      font-size: 12px;
      margin-left: 8px;
    }
  }
}
