@import "../form.scoped.scss";

.date-range-input {
  margin: 0px 13px 4px 0px !important;
  label {
    font-size: 12px;
    margin-bottom: 4px !important;
  }
  &-wrapper {
    overflow: hidden;
    padding: 0;
    margin: 0;
    &.error-input {
      div {
        border: 1px solid red !important;
      }
    }
    input {
      font-size: 12px;
      width: 100%;
      height: 100%;
    }
    input[type="text"] {
      &::placeholder {
        color: #c3c3c3;
        font-size: 12px;
      }
      width: 100%;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="date"] {
      width: 142px;
    }
  }
}
